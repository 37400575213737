import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { HelpTabs } from 'app/data/enum/help/HelpTabs';
import { HelpTourWizardAnchor } from 'app/data/local/HelpTourWizardAnchor';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-help',
  templateUrl: './HelpComponent.html',
  styleUrls: [ './HelpComponent.scss' ]
})
export class HelpComponent implements OnInit, OnDestroy {
  public readonly TourWizardAnchor: typeof HelpTourWizardAnchor = HelpTourWizardAnchor;

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public helpTabs: string[] = [ HelpTabs.FAQ, HelpTabs.TUTORIAL, HelpTabs.CONTACT ];
  public tabDefinition: any[] = [
    {
      tourWizardAnchor: HelpTourWizardAnchor.FAQ
    },
    {
      tourWizardAnchor: HelpTourWizardAnchor.TUTORIAL
    },
    {
      tourWizardAnchor: HelpTourWizardAnchor.CONTACT
    }
  ]
  public activeTab: string = HelpTabs.FAQ;
  public availableTabs: typeof HelpTabs = HelpTabs;
  private readonly canDisplayOnboardingTour: boolean = false;
  private onboardingTourStepList: TourWizardStep[] = [];
  public currentUser: UserDTO;
  constructor(private applicationModel: ApplicationModel,
              private uiRouterGlobals: UIRouterGlobals,
              private portalUtil: PortalUtil,
              private viewUtil: ViewUtil,
              private viewContainerRef: ViewContainerRef,
              private tourWizardService: TourWizardService,
              private breakpointObserver: BreakpointObserver,
              private userModel: UserModel) {
    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;

    this.onboardingTourStepList = [
      {
        anchorId: HelpTourWizardAnchor.FAQ,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_FAQ.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_FAQ.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: HelpTourWizardAnchor.TUTORIAL,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_TUTORIAL.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_TUTORIAL.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: HelpTourWizardAnchor.CONTACT,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_CONTACT.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HELP_CONTACT.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ]
  }

  public ngOnInit(): void {
    const currentStateName: string = this.uiRouterGlobals.current.name;

    this.applicationModel.selectSideBarItemWithState(
      currentStateName === State.MAIN.FAQ
        ? State.MAIN.FAQ
        : currentStateName === State.MAIN.HELP
          ? State.MAIN.HELP
          : null
    );

    if (currentStateName === State.MAIN.FAQ) {
      this.helpTabs = [ HelpTabs.FAQ, HelpTabs.TUTORIAL ];
    }

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    // this.startOnboardingTour()
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public isFaqRoute(): boolean {
    return this.uiRouterGlobals.current.name === State.MAIN.FAQ;
  }

  public onTabChanged(activeTab: string): void {
    this.activeTab = activeTab;
  }
  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.HELP) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement?.classList.add('tour-wizard-progress');
        tourStepElement?.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement?.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.HELP))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    }, 1000);
  }
}
