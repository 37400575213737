import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import {
  OnDemandVideoFilterModalComponent
} from 'app/component/view/main/onDemand/common/filters/OnDemandVideoFilterModalComponent';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { VideoSeniorDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorDTO';
import { VideoPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoPageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { SortDirection } from 'app/data/enum/SortDirection';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { VideoSortBy } from 'app/data/enum/video/VideoSortBy';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { OnDemandTourWizardAnchor } from 'app/data/local/OnDemandTourWizardAnchor';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { filter, first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-on-demand-all-videos',
  templateUrl: './OnDemandAllVideosComponent.html',
  styleUrls: [ './OnDemandAllVideosComponent.scss' ]
})
export class OnDemandAllVideosComponent implements OnInit {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;
  private readonly canDisplayOnboardingTour: boolean = false;
  public readonly maxNumberOfPages: number = 5;

  public currentUser: UserDTO;

  public internalPage: number = 1;

  public page: PageDTO<VideoSeniorDTO>;

  public internalSearchPhrase: string;

  public criteria: VideoPageCriteriaDTO;

  public selectedCategory: VideoCategory;

  public sortByOptions: OptionItem<string>[] = this.viewUtil.createEnumSelectOptions(VideoSortBy, 'VIDEO_SORT_BY');
  public sortValue: string;
  private onboardingTourStepList: TourWizardStep[] = [];
  public readonly TourWizardAnchor: typeof OnDemandTourWizardAnchor = OnDemandTourWizardAnchor;
  constructor(private onDemandVideoModel: OnDemandVideoModel,
              private viewUtil: ViewUtil,
              private modalService: BsModalService,
              private userModel: UserModel,
              private stateService: StateService,
              public accessControlModel: AccessControlModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private tourWizardService: TourWizardService,
              private breakpointObserver: BreakpointObserver) {
    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;

    this.onboardingTourStepList = [
      {
        anchorId: OnDemandTourWizardAnchor.FILTER,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_FILTER.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_FILTER.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: OnDemandTourWizardAnchor.QUICK_FILTER,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_QUICK_FILTER.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_QUICK_FILTER.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: OnDemandTourWizardAnchor.THUMBNAILS,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_THUMBNAILS.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_THUMBNAILS.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.TOP,
          positionFixed: true
        }
      },
      {
        anchorId: OnDemandTourWizardAnchor.HEART,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_HEART.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_HEART.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.TOP,
          positionFixed: true
        }
      },
      {
        anchorId: OnDemandTourWizardAnchor.PROGRAMS,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_PROGRAMS.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ON_DEMAND_PROGRAMS.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ];
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    const criteria = new VideoPageCriteriaDTO();

    criteria.favorites = this.stateService.is(State.MAIN.SENIOR.ON_DEMAND.VIDEO.FAVORITES);

    this.onCriteriaChanged(criteria);

    if (this.accessControlModel.isFullAccess) {
      // this.startOnboardingTour();
    }
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onCriteriaChanged(criteria: VideoPageCriteriaDTO): void {
    this.criteria = criteria;
    this.criteria.pageNumber = 0;

    this.getOnDemandVideoPage();
  }

  public getOnDemandVideoPage(): void {
    this.onDemandVideoModel.getVideosPageSenior(this.criteria)
      .subscribe((page) => {
        this.page = page;
      });
  }

  public clearSearch(): void {
    this.internalSearchPhrase = undefined;
    this.onSearchCommitted();
  }

  public changeIsFavoriteVideo(video: VideoSeniorDTO): void {
    if (video.isFavorite) {
      this.onDemandVideoModel
        .removeVideosFromFavorites({ ids: [ video.id ] })
        .subscribe(() => this.getOnDemandVideoPage());
    }
    else {
      this.onDemandVideoModel.addVideosToFavorites({ ids: [ video.id ] }).subscribe(() => this.getOnDemandVideoPage());
    }
  }

  public changeCategory(category: VideoCategory): void {
    const criteria = _.cloneDeep(this.criteria);

    this.selectedCategory = category;

    criteria.anyOfCategories = category ? [ category ] : undefined;
    criteria.anyOfSubcategories = undefined;

    this.onCriteriaChanged(criteria);
  }

  public openFiltersModal(): void {
    const modal: BsModalRef = this.modalService.show(OnDemandVideoFilterModalComponent, {
      initialState: {
        videoCategory: this.selectedCategory,
        anyOfCoachIds: this.criteria.anyOfCoachIds,
        anyOfIntensities: this.criteria.anyOfIntensities,
        anyOfLanguages: this.criteria.anyOfLanguages,
        anyOfSubcategories: this.criteria.anyOfSubcategories
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter(
          (reason) =>
            reason === OnDemandVideoFilterModalComponent.FILTER_DATA ||
            reason === OnDemandVideoFilterModalComponent.CLEAR_FILTERS
        )
      )
      .subscribe(() => {
        const filters = modal.content.filters;
        const criteria = _.cloneDeep(this.criteria);

        criteria.anyOfSubcategories = filters.type.selectedTypes;
        criteria.anyOfCoachIds = filters.coaches.selectedCoaches;
        criteria.anyOfLanguages =
          Object.keys(filters.languages.checked).length > 0
            ? Object.keys(filters.languages.checked).filter((key) => filters.languages.checked[key])
            : null;
        criteria.anyOfIntensities =
          Object.keys(filters.intensities.checked).length > 0
            ? (Object.keys(filters.intensities.checked).filter(
              (key) => filters.intensities.checked[key]
            ) as IntensityLevel[])
            : null;

        this.onCriteriaChanged(criteria);
      });
  }

  public onSearchCommitted(): void {
    const criteria = _.cloneDeep(this.criteria);
    criteria.searchPhrase = this.internalSearchPhrase;

    this.onCriteriaChanged(criteria);
  }

  public onPageChanged(page: PageChangedEvent): void {
    this.criteria.pageNumber = page.page - 1;
    this.getOnDemandVideoPage();
  }

  public onSortOptionChange(sortBy: VideoSortBy) {
    const criteria = _.cloneDeep(this.criteria);

    if (!sortBy) {
      criteria.sortOrders = undefined;
    }
    else {
      let sortOrders: BaseCriteriaSortOrder;
      let sortField: string;
      let sortDirection: SortDirection = SortDirection.ASCENDING;

      switch (sortBy) {
        case VideoSortBy.MOST_RECENT: {
          sortField = 'createdDate';
          sortDirection = SortDirection.DESCENDING;
          break;
        }
        case VideoSortBy.ALPHABETIC: {
          sortField = 'title';
          break;
        }
      }

      sortOrders = `${ sortField } ${ sortDirection }`;
      criteria.sortOrders = [ sortOrders ];
    }

    this.onCriteriaChanged(criteria);
  }


  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.ON_DEMAND) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.ON_DEMAND))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    }, 1500);
  }
}
