import { TutorialType } from 'app/data/dto/user/TutorialType';
import { PermissionName } from 'app/data/enum/permission/PermissionName';
import { UserType } from 'app/data/enum/user/UserType';
import { DateUtil } from 'app/util/DateUtil';
import { Exclude, Transform, Type } from 'class-transformer';
import * as _ from 'lodash';
import { SubscriptionDTO } from 'app/data/enum/subscription/SubscriptionDTO';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

export class UserDTO extends BaseUserDTO {
  public nickName: string;

  public email: string;

  public phone: number;

  public userType: UserType;

  public permissions: PermissionName[];

  public enabled: boolean;

  public watchPartyLimitReached: boolean;

  public phoneNumber: string;

  @Type(() => SubscriptionDTO)
  public subscription: SubscriptionDTO;

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  public firstLogin: boolean;

  public status: string;

  public completedTutorials: TutorialType[];

  @Exclude()
  public get isAdmin(): boolean {
    return this.userType === UserType.ADMIN;
  }

  @Exclude()
  public get isCoach(): boolean {
    return this.userType === UserType.COACH;
  }

  @Exclude()
  public get isSenior(): boolean {
    return this.userType === UserType.SENIOR;
  }

  public hasPermission(permission: PermissionName): boolean {
    return _.includes(this.permissions, permission);
  }

  public hasAllPermissions(permissions: PermissionName[]): boolean {
    // could also be implemented alternatively (and more efficiently) as
    // return (_.difference(permissions, this.currentUser?.permissions).length === 0)
    // but this below is just easier to understand when looking at

    let results: boolean[] = [];

    _.forEach(permissions, (permission: PermissionName) => {
      results.push(this.hasPermission(permission));
    });

    return _.every(results, (result: boolean) => result === true);
  }

  public hasAtLeastOnePermission(permissions: PermissionName[]): boolean {
    // could also be implemented alternatively (and more efficiently) as
    // return (_.intersection(permissions, this.currentUser?.permissions).length > 0)
    // but this below is just easier to understand when looking at
    let results: boolean[] = [];

    _.forEach(permissions, (permission: PermissionName) => {
      results.push(this.hasPermission(permission));
    });

    return _.includes(results, true);
  }
}
