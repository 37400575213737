import { Injectable, Injector } from '@angular/core';
import { Event } from 'app/common/Event';
import { PageDTO } from 'app/data/dto/PageDTO';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { CurrentUserFeedbackRequestDTO } from 'app/data/dto/feedback/CurrentUserFeedbackRequestDTO';
import { NotificationDTO } from 'app/data/dto/notifications/NotificationDTO';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { ScheduledLiveClassSeniorPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassSeniorPageCriteriaDTO';
import { ScheduledLiveClassIdRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassIdRequestDTO';
import { ScheduledLiveClassRatingRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingRequestDTO';
import { ScheduledLiveClassRatingResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingResponseDTO';
import { ScheduledLiveClassUnratedResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassUnratedResponseDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { ScheduledLiveClassJoiningResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassJoiningResponseDTO';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { ScheduledLiveClassSeniorDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { CurrentUserProfileDTO } from 'app/data/dto/user/CurrentUserProfileDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserProfileDTO } from 'app/data/dto/user/UserProfileDTO';
import { PermissionName } from 'app/data/enum/permission/PermissionName';
import { FeedbackService } from 'app/service/FeedbackService';
import { NotificationService } from 'app/service/NotificationService';
import { ScheduledLiveClassService } from 'app/service/ScheduledLiveClassService';
import { SeniorService } from 'app/service/SeniorService';
import { UserService } from 'app/service/UserService';
import { EventManager } from 'app/util/other/EventManager';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformDataRequestDTO } from 'app/data/dto/PlatformDataRequestDTO';
import { ScheduledLiveClassEnrollmentRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassEnrollmentRequestDto';
import { UserType } from 'app/data/enum/user/UserType';
import { LiveClassSeriesDecisionModalComponent } from 'app/component/view/main/liveClasses/components/modal/LiveClassSeriesDecisionModalComponent';
import { switchMap } from 'rxjs/operators';
import { LiveClassType } from 'app/data/enum/liveClass/LiveClassType';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { Constant } from 'app/common/Constant';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';
import { MyScheduleResponseDTO } from 'app/data/dto/schedule/MyScheduleResponseDTO';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';

@Injectable({ providedIn: 'root' })
export class UserModel {
  public currentUser$: BehaviorSubject<UserDTO> = new BehaviorSubject<UserDTO>(undefined);
  public currentUserObservable$: Observable<UserDTO> = this.currentUser$.asObservable();

  constructor(private applicationModel: ApplicationModel,
              private eventManager: EventManager,
              private injector: Injector,
              private readonly seniorService: SeniorService,
              private readonly userService: UserService,
              private readonly scheduledLiveClassService: ScheduledLiveClassService,
              private readonly feedbackService: FeedbackService,
              private readonly notificationService: NotificationService
  ) {
    this.setupListeners();
  }

  public get currentUser(): UserDTO {
    return this.currentUser$.value;
  }

  public set currentUser(value: UserDTO) {
    this.currentUser$.next(value);
  }

  private setupListeners(): void {
    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.currentUser = null;
    });
    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, (_result) => {
      this.currentUser = null;
    });
  }

  public getCurrentUser(): Promise<UserDTO> {
    return this.userService
      .getCurrentUser()
      .toPromise()
      .then((result: { user: UserDTO; securityData: SecurityDataDTO }) => {
        this.currentUser = result.user;

        this.eventManager.broadcast(Event.USER.GET_CURRENT.SUCCESS, result.user);
        this.eventManager.broadcast(Event.USER.SECURITY_DATA, result.securityData);

        return result.user;
      })
      .catch((error) => {
        this.eventManager.broadcast(Event.USER.GET_CURRENT.ERROR, error);

        throw error;
      });
  }

  public setUserEnabledStatus(userId: number, enabled: boolean): Promise<UserDTO> {
    return this.userService
      .setUserEnabledStatus(userId, enabled)
      .toPromise()
      .then((user: UserDTO) => {
        return user;
      })
      .catch((error) => {
        throw error;
      });
  }

  public adminResetPassword(userId: number): Promise<void> {
    return this.userService
      .adminResetPassword(userId)
      .toPromise()
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  public getCurrentUserProfile(): Observable<UserProfileDTO> {
    return this.userService.getCurrentUserProfile();
  }

  public updateCurrentUserProfile(user: CurrentUserProfileDTO): Observable<UserProfileDTO> {
    return this.userService.updateCurrentUserProfile(user);
  }

  public currentUserHasAnyOfType(types: UserType[]): boolean {
    return types.includes(this.currentUser?.userType);
  }

  public currentUserHasPermission(permission: PermissionName): boolean {
    return this.currentUser?.hasPermission(permission) ?? false;
  }

  public currentUserHasAllPermissions(permissions: PermissionName[]): boolean {
    return this.currentUser?.hasAllPermissions(permissions) ?? false;
  }

  public currentUserHasAtLeastOnePermission(permissions: PermissionName[]): boolean {
    return this.currentUser?.hasAtLeastOnePermission(permissions) ?? false;
  }

  public getScheduledLiveClassesPage(
    criteria: ScheduledLiveClassSeniorPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageUser(criteria);
  }

  public getScheduledLiveClassDetails(id: number): Observable<ScheduledLiveClassSeniorDetailsResponseDTO> {
    return this.scheduledLiveClassService.getScheduledLiveClassDetailsUser(id);
  }

  public registerToScheduledLiveClasses(scheduledLiveClasses: BaseLiveClass[]): Observable<void> {
    const enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO = new ScheduledLiveClassEnrollmentRequestDTO();
    enrollmentRequest.scheduledLiveClassIds = scheduledLiveClasses.map(item => item.id);
    enrollmentRequest.platformData = this.applicationModel.platform;

    return this.registerToScheduledLiveClass(enrollmentRequest);
  }

  public registerToLiveClass(liveClass: BaseLiveClass): Observable<void> {
    const enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO = new ScheduledLiveClassEnrollmentRequestDTO();
    enrollmentRequest.scheduledLiveClassIds = [ liveClass.id ];
    enrollmentRequest.platformData = this.applicationModel.platform;

    return liveClass?.liveClassSeriesId ?
      this.registerToLiveClassSeries(enrollmentRequest) : this.registerToScheduledLiveClass(enrollmentRequest);
  }

  public joinScheduledLiveClasses(id: number, platformData: PlatformDataRequestDTO): Observable<ScheduledLiveClassJoiningResponseDTO> {
    return this.scheduledLiveClassService.joinScheduledLiveClasses(id, platformData);
  }

  public dropFromLiveClass(liveClass: BaseLiveClass): Observable<void> {
    return liveClass?.liveClassSeriesId ?
      this.dropFromLiveClassSeries(liveClass) : this.dropFromScheduledLiveClasses(liveClass.id);
  }

  public addScheduledLiveClassesToFavorite(
    scheduledLiveClassIdRequestDTO: ScheduledLiveClassIdRequestDTO[]
  ): Observable<void> {
    return this.scheduledLiveClassService.addScheduledLiveClassesToFavorite(scheduledLiveClassIdRequestDTO);
  }

  public removeScheduledLiveClassesFromFavorite(id: number): Observable<void> {
    return this.scheduledLiveClassService.removeScheduledLiveClassesFromFavorite(id);
  }

  public getUnratedScheduledLiveClasses(): Observable<ScheduledLiveClassUnratedResponseDTO[]> {
    return this.scheduledLiveClassService.getUnratedScheduledLiveClasses();
  }

  public getSingleUnratedScheduledLiveClass(): Observable<ScheduledLiveClassUnratedResponseDTO> {
    return this.scheduledLiveClassService.getSingleUnratedScheduledLiveClass();
  }

  public getMySchedule(criteria: MyScheduleCriteriaDTO): Observable<MyScheduleResponseDTO> {
    return this.seniorService.getMySchedule(criteria);
  }

  public resendConfirmationCode(username: string): Observable<unknown> {
    const registrationType: RegistrationType = (username.match(Constant.EMAIL_ADDRESS_PATTERN) ? RegistrationType.EMAIL : RegistrationType.PHONE);
    return this.userService.resendConfirmationCode(username, registrationType);
  }

  public rateScheduledLiveClass(
    scheduledLiveClassId: number,
    ratingRequest: ScheduledLiveClassRatingRequestDTO
  ): Observable<ScheduledLiveClassRatingResponseDTO> {
    return this.scheduledLiveClassService.rateScheduledLiveClass(scheduledLiveClassId, ratingRequest);
  }

  public sendUserFeedback(feedback: CurrentUserFeedbackRequestDTO): Observable<void> {
    return this.feedbackService.sendUserFeedback(feedback);
  }

  public getNotification(): Observable<NotificationDTO[]> {
    return this.notificationService.getNotification();
  }

  public markNotificationsAsRead(id: number): Observable<void> {
    return this.notificationService.markNotificationsAsRead(id);
  }

  public joinToScheduledLiveClassWaitingList(scheduledLiveClassId: number, platformData: PlatformDataRequestDTO): Observable<void> {
    return this.scheduledLiveClassService.joinWaitingList(scheduledLiveClassId, platformData);
  }

  public dropFromScheduledLiveClassWaitingList(scheduledLiveClassId: number): Observable<void> {
    return this.scheduledLiveClassService.dropWaitingList(scheduledLiveClassId);
  }

  public setCurrentUserTutorialCompletion(value: TutorialType): Observable<UserDetailsDTO> {
    return this.userService.setCurrentUserTutorialCompletion(value);
  }

  private dropFromLiveClassSeries(liveClass: BaseLiveClass): Observable<void> {
    const modalService = this.injector.get(BsModalService);

    return modalService.show(LiveClassSeriesDecisionModalComponent, {
      initialState: {
        title: 'VIEW.MAIN.LIVE_CLASSES.SERIES.MESSAGE.DROP.TITLE'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap((result: LiveClassType) =>
          result === LiveClassType.SERIES ?
            this.scheduledLiveClassService.dropFromLiveClassSeries(liveClass.id) :
            this.scheduledLiveClassService.dropFromScheduledLiveClasses(liveClass.id)
        )
      );
  }

  private dropFromScheduledLiveClasses(id: number): Observable<void> {
    return this.scheduledLiveClassService.dropFromScheduledLiveClasses(id);
  }

  private registerToScheduledLiveClass(enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO): Observable<void> {
    return this.scheduledLiveClassService.registerToScheduledLiveClasses(enrollmentRequest);
  }

  private registerToLiveClassSeries(enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO): Observable<void> {
    const modalService = this.injector.get(BsModalService);

    return modalService.show(LiveClassSeriesDecisionModalComponent, {
      initialState: {
        title: 'VIEW.MAIN.LIVE_CLASSES.SERIES.MESSAGE.RESERVE.TITLE'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap((result: LiveClassType) =>
          result === LiveClassType.SERIES ?
            this.scheduledLiveClassService.registerToLiveClassSeries(enrollmentRequest) :
            this.scheduledLiveClassService.registerToScheduledLiveClasses(enrollmentRequest)
        )
      );
  }
}
