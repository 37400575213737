import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import ZoomMtgEmbedded, {
  EmbeddedClient,
  InitOptions,
  JoinOptions,
  SuspensionViewType
} from '@zoom/meetingsdk/embedded';
import { State } from 'app/common/State';
import { ZoomConfiguration } from 'app/data/local/zoom/ZoomConfiguration';

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './ZoomMeetingComponent.html',
  styleUrls: [ './ZoomMeetingComponent.scss' ]
})
export class ZoomMeetingComponent implements OnInit, OnDestroy {
  @Input() public config: ZoomConfiguration;
  @Input() public username: string = 'Anonymous';

  @ViewChild('zoomEmbedWrapper', { static: true })
  private readonly zoomEmbedWrapper: ElementRef<HTMLElement>;

  @ViewChild('zoomEmbedOptionsWrapper', { static: true })
  private readonly zoomEmbedOptionsWrapper: ElementRef<HTMLElement>;

  private zoomEmbedWrapperResizeObserver: ResizeObserver;

  private zoomClient: typeof EmbeddedClient;
  private zoomInitOptions: InitOptions;

  public isClosedConnection: boolean = false;

  public State: typeof State = State;

  constructor(private ngZone: NgZone,
              private cdr: ChangeDetectorRef) {
    this.zoomClient = ZoomMtgEmbedded.createClient();
  }

  ngOnInit(): void {
    this.initZoomEmbedOptions();
    this.startMeeting();
  }

  ngOnDestroy() {
    if (this.zoomEmbedWrapperResizeObserver) {
      this.zoomEmbedWrapperResizeObserver.disconnect();
    }

    ZoomMtgEmbedded.destroyClient();
  }

  private initZoomEmbedOptions(): void {
    const embedWrapper: HTMLElement = this.zoomEmbedWrapper.nativeElement;
    const optionsWrapper: HTMLElement = this.zoomEmbedOptionsWrapper.nativeElement;

    this.zoomInitOptions = {
      zoomAppRoot: embedWrapper,
      leaveOnPageUnload: true,
      language: 'en-US',
      patchJsMedia: true,
      customize: {
        video: {
          isResizable: false,
          defaultViewType: SuspensionViewType.Gallery,
          viewSizes: {
            default: {
              width: embedWrapper.offsetWidth,
              height: embedWrapper.offsetWidth * (9 / 16)
            }
          },
          popper: {
            disableDraggable: true
          }
        },
        chat: {
          popper: {
            placement: 'top-end',
            anchorElement: optionsWrapper
          }
        },
        setting: {
          popper: {
            placement: 'top-end',
            anchorElement: optionsWrapper
          }
        },
        participants: {
          popper: {
            placement: 'top-end',
            anchorElement: optionsWrapper
          }
        },
        activeApps: {
          popper: {
            placement: 'top-end',
            anchorElement: optionsWrapper
          }
        },
        meeting: {
          popper: {
            placement: 'top-end',
            anchorElement: optionsWrapper
          }
        }
      }
    };
  }

  public startMeeting(): void {
    const { meetingNumber, password, sdkKey, signature } = this.config;

    this.ngZone.runOutsideAngular(() => {
      this.zoomClient.init(this.zoomInitOptions)
        .then(() => this.zoomClient.join({
          userName: this.username,
          meetingNumber,
          password,
          sdkKey,
          signature
        } as JoinOptions))
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.observeZoomEmbedWrapperResize();

          this.zoomClient.on('connection-change', (payload: any) => {
            if (payload.state === 'Closed') {
              this.isClosedConnection = true;
              this.cdr.detectChanges();
            }
          });

        });
    });
  }

  private observeZoomEmbedWrapperResize(): void {
    this.zoomEmbedWrapperResizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          this.updateZoomEmbedSize(entry.contentRect.width);
        }
      }
    );

    this.zoomEmbedWrapperResizeObserver.observe(this.zoomEmbedWrapper.nativeElement);
  }

  private updateZoomEmbedSize(width: number): void {
    this.zoomClient.updateVideoOptions({
      viewSizes: {
        default: {
          width: width,
          height: width * (9 / 16)
        }
      }
    });
  }

}
