<div class="zoom-embed-container">
  <figure id="zoom-embed-wrapper" #zoomEmbedWrapper></figure>
  <figure id="zoom-embed-options" #zoomEmbedOptionsWrapper>
  </figure>

  <ng-container *ngIf="isClosedConnection">
    <div class="d-flex flex-column justify-content-center">
      <div class="flex-grow-0 text-center">
        <h2>{{ 'COMPONENT.ZOOM_EMBED.MESSAGE.DISCONNECTED' | translate }}</h2>

        <div class="d-flex justify-content-center gap-4 mt-4">
          <a href="/" class="btn btn-primary">{{ 'VIEW.MAIN.SIDEBAR.DASHBOARD' | translate }}</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>