import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';
import { SeniorActivityCriteriaDTO } from 'app/data/dto/senior/SeniorActivityCriteriaDTO';
import { SeniorActivityDTO } from 'app/data/dto/senior/SeniorActivityDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { MyActivityTourWizardAnchor } from 'app/data/local/MyActivityTourWizardAnchor';
import { SeniorModel } from 'app/model/SeniorModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-my-activity',
  templateUrl: 'MyActivityComponent.html',
  styleUrls: [ 'MyActivityComponent.scss' ]
})
export class MyActivityComponent implements OnInit, OnDestroy {
  public readonly TourWizardAnchor: typeof MyActivityTourWizardAnchor = MyActivityTourWizardAnchor;
  public static readonly RECENT_BADGES_COUNT: number = 3;

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;
  private readonly canDisplayOnboardingTour: boolean = false;
  private onboardingTourStepList: TourWizardStep[] = [];
  public currentUser: UserDTO;
  public timeFrames: string[] = [
    'COMPONENT.MY_ACTIVITY.THIS_DAY',
    'COMPONENT.MY_ACTIVITY.THIS_WEEK',
    'COMPONENT.MY_ACTIVITY.THIS_MONTH',
    'COMPONENT.MY_ACTIVITY.THIS_YEAR'
  ];

  public currentIndex: number = 2;
  public currentTimeFrame: string = this.timeFrames[this.currentIndex];

  public seniorActivity: SeniorActivityDTO;
  public unratedActivityCount: number;
  public totalActivityTimeFormatted: string;

  public progressBarItems: { text: string; value: number }[];

  public badges: BadgeResponseDTO[];

  constructor(private stateUtil: StateUtil,
              private seniorModel: SeniorModel,
              private translate: TranslateService,
              private viewUtil: ViewUtil,
              private portalUtil: PortalUtil,
              private userModel: UserModel,
              private viewContainerRef: ViewContainerRef,
              private tourWizardService: TourWizardService,
              private breakpointObserver: BreakpointObserver) {
    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;

    this.onboardingTourStepList = [
      {
        anchorId: MyActivityTourWizardAnchor.TOTAL_ACTIVITY,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_TOTAL_ACTIVITY.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_TOTAL_ACTIVITY.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: MyActivityTourWizardAnchor.SUMMARY,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACTIVITY_SUMMARY.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACTIVITY_SUMMARY.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.RIGHT,
          positionFixed: true
        }
      },
      {
        anchorId: MyActivityTourWizardAnchor.BADGES,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACTIVITY_BADGES.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACTIVITY_BADGES.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ]
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getSeniorActivity();
    this.getSeniorBadges();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public getSeniorActivity(): void {
    const criteria: SeniorActivityCriteriaDTO = new SeniorActivityCriteriaDTO();
    const { dateFrom, dateTo } = this.createCriteriaBasedOnTimeFrame();
    criteria.dateFrom = dateFrom;
    criteria.dateTo = dateTo;

    this.seniorModel.getCurrentSeniorActivity(criteria)
      .subscribe((activity: SeniorActivityDTO) => {
          this.seniorActivity = activity;
          this.unratedActivityCount = activity.videosToRate?.length + activity.liveClassesToRate?.length;
          this.progressBarItems = this.calculatePercentages(activity);
          // this.startOnboardingTour()
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  private getSeniorBadges(): void {
    this.seniorModel.getEarnedBadgesForCurrentSenior()
      .subscribe((badges: BadgeResponseDTO[]) => {
        this.badges = badges.slice(0, MyActivityComponent.RECENT_BADGES_COUNT);
      });
  }

  public calculatePercentages(response) {
    const totalDuration = response.summary.liveClassesTakenDuration + response.summary.videosWatchedDuration;
    this.formatTotalActivityTime(totalDuration);

    if (totalDuration > 0) {
      const liveClassesPercentage: number = Math.round((response.summary.liveClassesTakenDuration / totalDuration) * 100);
      const onDemandClassesPercentage: number = Math.round((response.summary.videosWatchedDuration / totalDuration) * 100);

      return [
        { text: this.translate.instant('Live classes'), value: liveClassesPercentage },
        { text: this.translate.instant('On-Demand'), value: onDemandClassesPercentage }
      ];
    }

    return [
      { text: this.translate.instant('Live classes'), value: 0 },
      { text: this.translate.instant('On-Demand'), value: 0 }
    ];
  }

  public changeTimeFrame(direction: 'previous' | 'next'): void {
    if (direction === 'next') {
      this.currentIndex = (this.currentIndex + 1) % this.timeFrames.length;
    }
    else {
      this.currentIndex = (this.currentIndex - 1 + this.timeFrames.length) % this.timeFrames.length;
    }
    this.currentTimeFrame = this.timeFrames[this.currentIndex];
    this.getSeniorActivity();
  }

  public createCriteriaBasedOnTimeFrame() {
    const dateFrom: Date = new Date();
    let dateTo: Date = new Date();
    const currentTimeFrame = this.translate.instant(this.currentTimeFrame);

    switch (currentTimeFrame) {
      case 'This day':
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 999);
        break;
      case 'This week':
        const dayOfWeek = dateFrom.getDay();
        const difference = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        dateFrom.setDate(dateFrom.getDate() + difference);
        dateFrom.setHours(0, 0, 0, 0);
        dateTo = new Date(dateFrom);
        dateTo.setDate(dateFrom.getDate() + 6);
        dateTo.setHours(23, 59, 59, 999);
        break;
      case 'This month':
        dateFrom.setDate(1);
        dateFrom.setHours(0, 0, 0, 0);
        dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0);
        dateTo.setHours(23, 59, 59, 999);
        break;
      case 'This year':
        dateFrom.setFullYear(dateFrom.getFullYear(), 0, 1);
        dateFrom.setHours(0, 0, 0, 0);
        dateTo = new Date(dateFrom.getFullYear(), 11, 31);
        dateTo.setHours(23, 59, 59, 999);
        break;
    }

    return { dateFrom, dateTo };
  }

  public formatTotalActivityTime(totalMinutes: number): void {
    if (totalMinutes > 0) {
      const hours: number = Math.floor(totalMinutes / 60);
      const minutes: number = totalMinutes % 60;
      this.totalActivityTimeFormatted = `${ hours } hr ${ minutes } min`;
    }
    else {
      this.totalActivityTimeFormatted = '0 hr 0 min';
    }
  }

  public onAllBadgesClick(): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.MY_ACTIVITY.ALL_BADGES);
  }

  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.MY_ACTIVITY) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement?.classList.add('tour-wizard-progress');
        tourStepElement?.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement?.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.MY_ACTIVITY))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    }, 1000);
  }
}
